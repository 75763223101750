<template>
  <div class="message">
    <center-title title="消息中心" bottom="0"></center-title>
    <div class="message-list">
      <router-link :to="`/messagedetail?id=${item.id}`" class="message-item row-between lighter xs"
        v-for="(item, index) in noticeList" :key="index">
        <div class="item-pro row ">
          <el-image class="pro-img mr20" :src="item.image" fit="contain" lazy></el-image>
          <div class="line1">{{ item.title }}</div>
        </div>
        <div class="item-date warn">{{ item.create_time }}</div>
      </router-link>
    </div>
    <el-empty description="暂无消息" v-if="!noticeList.length"></el-empty>
    <div class="row-end" v-if="noticeList.length">
      <make-pagination @resetList="resetList" :orderObject="orderObject" v-if="noticeList.length"></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      noticeList: [],
      page_no: 1,
      page_size: 10,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取消息列表
    getNoticeList() {
      this.$api.getMessageListApi({
      }).then(res => {
        let { list, count, page_size: size, page_no: page } = res.data;
        this.noticeList = list;
        this.orderObject = res.data;
        this.orderObject = {
          page,
          size,
          count,
          list,
        };
      })
    },
    resetList(e) {
      this.page_no = e;
      this.getNoticeList()
    },
  },
  created() {
    this.getNoticeList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.message-list {
  margin-bottom: 60px;

  .message-item {
    padding: 20px 0;
    border-bottom: $-solid-border;

    .item-pro {
      width: 80%;

      .pro-img {
        width: 87px;
        height: 72px;
        background-color: #ede2d4;
        flex-shrink: 0;
      }
    }
  }
}

.message {
  padding-bottom: 40px;
}
</style>